// import node_modules
import { Helmet } from 'react-helmet';
import React from 'react';

// import React components
import Layout from '../components/Layout';

const Impressum = () => (
  <Layout
    canonical="https://www.echogramm.de/impressum/"
    description="Impressum des Echogramms. Hier findest du unsere Impressumsdaten. Mehr erfahren."
    image="https://echogramm.de/img/echogramm-bubbles.jpg"
    lang="de"
    title="Echogramm | Impressum der Social News App"
  >
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <section id="impressum" className="section">
      <div className="container">
        <div className="columns is-mobile is-centered">
          <div className="column is-full-mobile is-three-quarters-tablet is-two-thirds-desktop is-three-fifths-widescreen is-half-fullhd has-padding-top-3">
            <h1 className="is-size-2">Impressum</h1>

            <h5 className="is-size-5 is-padding-top-1">Angaben gemäß § 5 TMG:</h5>
            <p>
              mii ventures GmbH
              <br />
              Fährbrücker Straße 8
              <br />
              D-97262 Hausen bei Würzburg
            </p>

            <h5 className="is-size-5 is-padding-top-1">Vertreten durch:</h5>
            <p>Michael Schmitt</p>

            <h5 className="is-size-5 is-padding-top-1">Kontakt:</h5>
            <p>
              Telefon: +49 (0) 9367 4088977
              <br />
              E-Mail: hello@mii.ventures
            </p>

            <h5 className="is-size-5 is-padding-top-1">Registereintrag:</h5>
            <p>
              Eintragung im Handelsregister
              <br />
              Registergericht: Amtsgericht Würzburg
              <br />
              Registernummer: HRB 12645
            </p>

            <h5 className="is-size-5 is-padding-top-1">Umsatzsteuer-ID:</h5>
            <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE298502783</p>

            <h5 className="is-size-5 is-padding-top-1">
              Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
            </h5>
            <p>Michael Schmitt</p>

            <h5 className="is-size-5 is-padding-top-1">
              Quellenangaben für die verwendeten Bilder und Grafiken:
            </h5>
            <p>unsplash.com</p>

            <h5 className="is-size-5 is-padding-top-1">Rechtliche Hinweise</h5>
            <p>
              <a
                href="http://ec.europa.eu/consumers/odr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Link zur Plattform der Europäischen Kommission
              </a>
              &nbsp;gemäß der Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="demo" className="section bg-demo">
      <div className="container">
        <div className="columns is-mobile is-centered">
          <div className="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop is-two-thirds-widescreen is-three-fifths-full-hd has-text-centered">
            <h2 className="is-size-2">Demo anfordern</h2>
            <p className="is-size-5">
              Das Echogramm ist eine Social News App, die dir alle wichtigsten News deiner Branche
              auf einen Blick liefert. In einem persönlichen Gespräch zeigen wir dir die Funktionen
              und Möglichkeiten des Echogramms für dein Unternehmen. Du möchtest frischen Wind in
              deiner und der Filterblase deines Teams? Fordere jetzt unverbindlich eine persönliche
              Produktvorführung an.
            </p>
            <p>
              <a
                href="mailto:echogramm@mii.ventures?subject=Produktdemo Echogramm"
                className="button is-echogramm"
              >
                Produktdemo anfordern
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default Impressum;
